<template>
    <!-- Footer Area -->

    <footer class="footer-area">
        <div class="container">
            <div class="footer-up">
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="logo">
                            <img src="/assets/img/goodcologowhite.png" alt="gc-logo">
                        </div>
                        <div class="contact-info">
                            <p><b>Address:</b> {{homeData.address}}</p>
                            <p><b>Phone:</b> {{homeData.phone}}</p>
                            <p><b>Email:</b> {{homeData.mail}}</p>
                            <p><b>Opening Hours:</b> {{homeData.opening_hour}}</p>
                        </div>

                    </div>
                    <div class="col-lg-5 col-md-6 com-sm-12">

                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-12">
                                <h6>Navigation</h6>
                                <ul>
                                    <li>
                                        <router-link :to="{name:'About'}">About Us</router-link>
                                        <router-link :to="{name:'Services'}">Services</router-link>
                                        <router-link :to="{name:'ChooseUs'}">Why Choose Us</router-link>
                                        <router-link :to="{name:'Faq'}">FAQ</router-link>
                                        <router-link class="nav-link" :to="{name:'Contact'}">Contact</router-link>
                                       <!--<router-link :to="{name:'Attorney'}">Meet Our Team</router-link>
                                        <router-link :to="{name:'Blog'}">News & Media</router-link>
                                        <router-link :to="{name:'Case'}">Case Studies</router-link>-->
                                    </li>
                                </ul>
                            </div>
                            <div class="col-lg-6 col-md-6 col-12">
                                <h6>Practice Areas</h6>
                                <ul>
                                    <li>
                                        <router-link v-for="service in services.slice(0,10)" :key="service.id" :to="{name:'ServiceDetails', params:{ id: service.id} }" @click="singleService">{{service.name}}</router-link>
                                        
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6">
                        <div class="subscribe-form">
                            <h6>Regulated by the SRA</h6>
                            <!--<form action="index.html">
                                <input type="email" placeholder="Your email">
                                <button type="submit"><i class="las la-envelope"></i></button>
                            </form>
                            <p>Stay tuned for our latest news</p>-->
                            <!-- Start of SRA Digital Badge code -->
                            <div style="max-width:275px;max-height:163px;"><div style="position: relative;padding-bottom: 59.1%;height: auto;overflow: hidden;"><iframe frameborder="0" scrolling="no" allowTransparency="true" src="https://cdn.yoshki.com/iframe/55845r.html" style="border:0px; margin:0px; padding:0px; backgroundColor:transparent; top:0px; left:0px; width:100%; height:100%; position: absolute;"></iframe></div></div>
                            <!-- End of SRA Digital Badge code -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>

    <!-- Footer Bottom Area  -->

    <div class="footer-bottom">
        <div class="container">
            <div class="row justify-content-center align-items-center">
                <div class="col-lg-3 col-12">
                    <div class="copyright-area">
                        <p class="copyright-line">© 2023 Good & Co Solicitors. All rights reserved.</p>
                    </div>
                </div>
                <div class="col-lg-6 col-12">
                    <!--<p class="privacy"><a href="#">Terms &amp; Conditions</a> <a href="#">Privacy Policy</a> <a href="#">Case Review</a> </p>-->
                </div>
                <div class="col-lg-3 col-12 text-end">
                    <div class="social-area">
                        <a href="https://m.facebook.com/p/Good-Co-Solicitors-100069542034238/" target="_blank"><i class="lab la-facebook-f"></i></a>
                        <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fgoodandco" target="_blank"><i class="lab la-twitter"></i></a>
                        <!--<a href=""><i class="lab la-instagram"target="_blank" ></i></a>
                        <a href=""><i class="lab la-youtube" target="_blank"></i></a>
                        <a href=""><i class="lab la-linkedin" target="_blank"></i></a>-->
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import axios from 'axios'
export default {
    data(){
        return {
            homeData : [],
            services : []
        }
    },

    async mounted(){
        let fetchedData = await axios.get("/assets/data/homeData.json");
        this.homeData = fetchedData.data.homeData;

        let fetchedServiceData = await axios.get("/assets/data/services.json");
        this.services = fetchedServiceData.data.services;
    },

    methods:{
        singleService(){
            this.$parent.reRenderData();
            
            //scrolling to top of the window
            window.scrollTo(0, 0);
        }
    }

}
</script>